import Children from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"


type Props = {
}


function Partnerships({ }: Props) {

  return <div className={`bg-gray-200 w-full flex flex-col items-center`}>

    <div className={`bg-white w-full flex flex-col items-center pb-8`}>
      <div className={`w-full max-w-4xl pt-10 px-10 flex flex-col items-center`}>

        <div className={`flex md:flex-row flex-col-reverse gap-8 md:text-left text-center items-center md:items-start`}>
          <div>
            <h3 className={`mb-6 font-righteous uppercase text-4xl text-brandblue`}>
              Partnerships</h3>

            <p className={`text-lg text-gray-600`}>Partnering with ChecQR gives you access to cutting-edge tools and software, designed specifically to help your clients keep up with the checking and record keeping tasks required by the HACCP,  SMS, or other management system you craft for them. By collaborating with us, independent consultants gain access to premium software which helps transform policies and recommendations into measurable change in their clients businesses.</p>
          </div>

          <img src={`/images/hi-vis-ipad.jpg`}
            alt={''}
            className={`md:w-1/2 w-full`}
            style={{
              borderRadius: '50%',
              width: '220px'
            }}
          />
        </div>
      </div>
    </div>
    <div className={`w-full max-w-4xl pb-10 px-10 flex flex-col items-center`}>
      <div className={`mt-6 flex md:flex-row md:gap-10 flex-col gap-2`}>
        <div className={`flex flex-col flex-1 gap-2`}>

          <p>We work closely to ensure our solutions are practical, effective, seamlessly integrated and easy to start using.  We have worked with consultants and end users throughout the development of our product to make sure implementation is easy and checks are well aligned with the requirements defined.</p>
          <p>We operate a revenue share model that recognizes and rewards our partners' efforts. If your client signs a one-year contract, your profit share continues for the duration of that year, and extends when the client adopts additional products and services.</p>
        </div>


        <div className={`flex flex-col flex-1 gap-2`}>
          <p>Our partner consultants have the flexibility to lead or refer their clients to ChecQR as needed. We value the strong relationships you've built with your clients, and we handle every interaction with the utmost care.</p>
          <p>Through our partnership program, we collaborate with consultants and manufacturers to deliver solutions tailored to their customers' needs. We offer both off-the-shelf and bespoke solutions, supported by a revenue share model.</p>
          <p><Link to={'/contact'} className={`underline hover:opacity-80`}>Get in touch with us</Link> today to join our partnership programme.
  </p>
        </div>

      </div>
    </div>
  </div>
}

export default Partnerships