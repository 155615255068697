import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"




function Header() {


  return <div className={`w-full pt-8 pb-8 flex flex-col items-center`} style={{ background: 'linear-gradient(rgba(22, 28, 46, 0.8), rgba(22, 28, 46, 0.8)), url(/images/jessica-lewis-fJXv46LT7Xk-unsplash.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <div className={`w-full max-w-4xl px-10`}>
      <Link to='/'><img className={`w-48`} src='/logos/logo-white-no-strapline.svg' alt='ChecQR Logo' /></Link>
    </div>
  </div>
}

export default Header