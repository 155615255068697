import Children from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    json,
} from "react-router-dom"


type Props = {
}


function Retail({ }: Props) {

    return <div className={`bg-gray-200 w-full flex flex-col items-center md:text-left text-center`}>

        <div className={`bg-white w-full flex flex-col items-center pb-8`}>
            <div className={`w-full max-w-4xl pt-10 px-10 flex flex-col items-center`}>

                <div className={`flex md:flex-row flex-col-reverse gap-8 md:text-left text-center items-center`}>
                    <div>
                        <h3 className={`mb-6 font-righteous uppercase text-4xl text-brandblue`}>
                            Operational record keeping for retail
                        </h3>

                        <p className={`text-lg text-gray-600`}>
                            ChecQR is a platform for performing operational checks with mobile phones and tablets.
                        </p>
                    </div>

                    <img src={`/images/tara-clark-Gk8LG7dsHWA-unsplash.jpg`}
                        alt={''}
                        className={`md:w-1/2 w-full`}
                        style={{
                            borderRadius: '50%',
                            width: '220px'
                        }}
                    />
                </div>
            </div>
        </div>
        <div className={`w-full max-w-4xl pb-10 px-10 flex flex-col md:items-start items-center`}>

            <h3 className={`text-center md:text-left mt-6 mb-4 font-righteous uppercase text-2xl text-brandblue`}>
                Checqr consists of:</h3>

            <div className={`flex md:flex-row md:gap-10 flex-col gap-2`}>
                <div className={`flex flex-col flex-1`}>
                    <ul className={`pl-4 list-disc flex flex-col gap-2 list-inside md:list-outside`}>
                        <li>A simple, friendly experience for carrying out operational checks on mobile devices which is focused on telling the user what needs to be done and helping them to do it</li>

                        <li>A powerful dashboard with an easy to use builder to allow rapid creation of operational checking processes and instant access to results</li>

                        <li>A flexible notifications system to make sure the right people find out if expected checks are missed or results require further attention </li>
                    </ul>
                </div>

                <div className={`flex flex-col flex-1`}>
                    <ul className={`pl-4 list-disc flex flex-col gap-2`}>
                        <li>An audit portal to give logged, read only access to auditors to comply with contractual, legal or management system audit requirements</li>

                        <li>A corrective action process enabling issues to be flagged, addressed and closed and recorded by the appropriate individuals.</li>
                    </ul>
                </div>

            </div>
        </div>


        <div className={`bg-white w-full flex flex-col items-center pb-8 `}>
            <div className={`w-full max-w-4xl pb-10 px-10 flex flex-col md:items-start items-center`}>
                <p className={`mt-6 mb-2 text-lg text-gray-600`}>
                    Our friendly software makes it easy for retail staff to carry out checks and gather evidence across your operations.  Real time sharing of data and highly configurable alerts give local, regional and central managers and compliance teams a clear vision of current operational reality.</p>

                <div className={`gap-4 md:gap-0 flex md:flex-row flex-col flex-wrap`}>


                    <div className={`flex flex-col md:w-1/2 md:pr-4  w-full `}>
                        <h3 className={`text-center md:text-left mt-6 mb-4 font-righteous uppercase text-lg text-brandblue`}>Get a higher standard of evidence</h3>
                        <ul className={`pl-4 list-disc flex flex-col gap-2 list-inside md:list-outside`}>
                            <li>QR codes eliminate mix ups between similar looking equipment, doors, etc</li>

                            <li>
                                Live sharing of time stamped data eliminates errors in when data was recorded and removes the temptation to fill in records later
                            </li>
                        </ul>
                    </div>


                    <div className={`flex flex-col md:w-1/2 md:pl-4  w-full `}>
                        <h3 className={`text-center md:text-left mt-6 mb-4 font-righteous uppercase text-lg text-brandblue`}>Processes can be modified in real time</h3>
                        <ul className={`pl-4 list-disc flex flex-col gap-2 list-inside md:list-outside`}>
                            <li>Managers can update required checks and roll them out instantly, this eliminates the need to find and update lists in many places.
                            </li>
                            <li>All changes are recorded with an audit trail available
                            </li>
                        </ul>
                    </div>
                    <div className={`flex flex-col md:w-1/2 md:pr-4  w-full `}>

                        <h3 className={`text-center md:text-left mt-6 mb-4 font-righteous uppercase text-lg text-brandblue`}>Make operational checking easier for your staff and reduce time needed in training</h3>
                        <ul className={`pl-4 list-disc flex flex-col gap-2 list-inside md:list-outside`}>
                            <li>Help can be offered in app to ensure
                                Live sharing of data and alerts for managers means mistakes can be reacted to quickly and problems fixed earlier</li>

                            <li>
                                Advanced scheduling options, for example some checks on a list can be set to appear only on certain days.  The operational staff don’t need to remember any of this, they just have to do the checks that come up on the list for that day
                            </li>
                        </ul>
                    </div>

                    <div className={`flex flex-col md:w-1/2 md:pl-4  w-full `}>
                        <h3 className={`text-center md:text-left mt-6 mb-4 font-righteous uppercase text-lg text-brandblue`}>Comply more easily with your contractual and legal requirements</h3>
                        <ul className={`pl-4 list-disc flex flex-col gap-2 list-inside md:list-outside`}>
                            <li>ChecQR is designed to support formal management systems
                            </li>
                            <li>It’s easy to integrate checQR into digital management tools to synchronise data
                            </li>
                            <li>ChecQR helps a risk based approach by providing consistent, high quality data about operations and allows changes to checklists or schedules to be rolled out instantly.  E.g. it can often be identified from consistent good results that something which is being checked is low risk and then the frequency of the check can be reduced (perhaps from weekly to monthly) </li>
                        </ul>
                    </div>



                </div>
            </div>
        </div>

        <div className={`w-full max-w-4xl py-10 px-10 flex flex-col gap-6 md:flex-row md:items-start items-center`}>


            <div className={`flex flex-col items-center text-center  gap-3`}>
                <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-12`} />
                Easy, real-time, operational checking

            </div>
            <div className={`flex flex-col items-center text-center  gap-3`}>
                <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-12`} />
                Support for all mobile devices

            </div>
            <div className={`flex flex-col items-center text-center  gap-3`}>
                <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-12`} />
                Simple checklist builder

            </div>
            <div className={`flex flex-col items-center text-center  gap-3`}>
                <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-12`} />
                Advanced notification options

            </div>



        </div>
    </div >
}

export default Retail