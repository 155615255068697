import Children from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"


type Props = {
}


function Contact({ }: Props) {

  return <div className={`bg-gray-100 w-full flex flex-col items-center`}>
    <div className={`w-full max-w-4xl py-10 px-10 flex flex-col items-center`}>


      <div className={`flex md:flex-row flex-col gap-8`}>

        <div>
          <h3 className={`mb-6 font-righteous uppercase text-4xl text-brandblue`}>
            Contact Us</h3>
          <p>We'd love to hear from you! Contact us today to find out more about how ChecQR can work for your business.
          </p>
          <div className={`mt-4 flex flex-row gap-2`}>
            <a href='mailto:hello@checqr.net'
              className={`w-full text-center font-righteous uppercase text-white hover:bg-opacity-90 bg-brandblue font-bold py-2 px-4 rounded hover:opacity-90`}>Email us</a>
            <a href='https://calendly.com/will-checqr/intro-call'
              className={`w-full text-center font-righteous uppercase text-white hover:bg-opacity-90 bg-brandblue font-bold py-2 px-4 rounded hover:opacity-90`}>Book a call</a>
          </div>
        </div>

        <div className={`border border border-gray-200 p-4 bg-white rounded shadow flex flex-col gap-2 items-start`}>

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9182068320833!2d-0.03186818767850675!3d51.51471657169738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602cb55e052a5%3A0xa6495e905a349f0b!2s16%20Pixley%20St%2C%20London%20E14%207DF!5e0!3m2!1sen!2suk!4v1714481689479!5m2!1sen!2suk" className={`w-full h-56`} style={{
            minWidth: '240px'
          }}></iframe>
          <div className={`flex flex-col`}>
            <h4 className={`font-bold`}>Address:</h4>
            <p>16 Pixley Street, London, E147DF</p>
          </div>
          <div className={`flex flex-col`}>
            <h4 className={`font-bold`}>Phone:</h4>
            <p>07507659168</p>
          </div>
        </div>
      </div>


    </div>
  </div>
}

export default Contact