import React from 'react'
import Children, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import BlogArticles from '../combined-blogs.json'
import Spinner from './Spinner'
import { convertDateStringToTimestamp } from '../utils/convertDateStringToTimestamp'
import { getDate } from '../utils/getDate'

type ObjectAny = {
  [key: string]: any
}
type Props = {
}


function BlogList({ }: Props) {


  const [combinedArticles, setCombinedArticles] = useState<ObjectAny | null>(null)
  const [sortedArticleKeys, setSortedArticleKeys] = useState<Array<string> | null>(null)

  useEffect(() => {
    sortPostsByDate(BlogArticles)
  }, [])

  const sortPostsByDate = (posts: ObjectAny) => {
    const postsArray = Object.entries(posts).map(([key, value]) => ({ key, ...value }))
    postsArray.sort((a, b) => {
      const dateA = new Date(a.date.split('/').reverse().join('/'))
      const dateB = new Date(b.date.split('/').reverse().join('/'))
      //@ts-ignore
      return dateB - dateA
    })
    const sortedKeys = postsArray.map(post => post.key)
    setCombinedArticles({ ...BlogArticles })
    setSortedArticleKeys(sortedKeys)
  }



  return <div className={`bg-gray-200 w-full flex flex-col items-center`}>
    <div className={`w-full max-w-4xl py-10 px-10 flex flex-col items-center`}>
      <h3 className={`mt-4 mb-6 font-righteous uppercase text-4xl text-brandblue`}>
        Product update blog posts
        </h3>

      {combinedArticles && sortedArticleKeys ?
      <div className={`flex flex-wrap flex-row gap-2`}>
        {sortedArticleKeys.map((key: string, index: number) => {
          const thisArticle = combinedArticles[key]
          return <div key={index} className={`w-full md:w-64 border border border-gray-200 p-4 bg-white rounded shadow flex flex-col gap-2 justify-between`}>
            <div>

              {thisArticle.image && <Link to={`/blog/${key}`}><img
                className={`w-full rounded mb-4`}
                src={thisArticle.image}
                alt=''
              /></Link>}


              <h3 className={`font-righteous text-xl text-brandblue`}>
                {thisArticle.title}
              </h3>
              {thisArticle.date && <p className={`text-sm text-gray-600 italic`}>
              {getDate(convertDateStringToTimestamp(thisArticle.date), 'formattedDate')}
                </p>}
              <p className={`mt-2 mb-4`}>{thisArticle.summary}</p>
            </div>
            <Link to={`/blog/${key}`} className={`font-righteous uppercase mt-6 text-white hover:bg-opacity-90 bg-brandblue font-bold py-2 px-4 rounded hover:opacity-90 text-center`}>Read more</Link>
          </div>
        })}
      </div>
      :
      <Spinner><p>Loading...</p></Spinner>
      }




    </div>
  </div>
}

export default BlogList