import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"


type ProjectThumbnailProps = {
  title: string
  description: string
  button: string
  bgcolor: string
  textcolor: string
  image: string
  link: string
}

type GeneralContentSectionProps = {
  imageUrl: string
  title: string
  text: string
  buttonUrl: string
  buttonText: string
}


function ProjectThumbnail({ title, description, button, bgcolor, textcolor, image, link }: ProjectThumbnailProps) {

  return <div className={`border border-gray-300  rounded text-center w-full md:w-64 bg-white drop-shadow-xl flex flex-col items-center mb-10 pb-4 px-4 justify-between`}>
    <div className={`flex flex-col items-center`}>
      <img className={`w-24`} src={image} alt='Icon' style={{ transform: 'translate(0px, -20px)' }} />
      <h3 className={`font-righteous uppercase text-xl ${textcolor}`}>{title}</h3>
      <p className={`mt-1 text-sm`}>{description}</p>
    </div>
    {link && <a href={link} className={`font-righteous uppercase mt-4 ${bgcolor} hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded hover:opacity-90`}>{button}</a>}
  </div>
}

function GeneralContentSection({
  imageUrl,
  title,
  text,
  buttonUrl,
  buttonText,
}: GeneralContentSectionProps) {
  return <div className={`w-full max-w-4xl px-10 py-10 flex flex-col gap-2 justify-between  items-center`}>

    <div className={`w-full flex flex-col gap-2  items-center`}>
      <Link to={buttonUrl}
        className={``}>
        <img src={imageUrl}
          alt={''}
          className={`cursor-pointer hover:opacity-90 w-48`}
          style={{ borderRadius: '50%' }}
        /></Link>

      <div className={`w-full flex flex-col gap-2  text-center justify-center  items-center`}>
        <h2 className={`mt-4 font-righteous uppercase text-brandblue text-xl font-bold`}>{title}</h2>
        <p>{text}</p>
      </div>
    </div>

    <Link to={buttonUrl} className={`font-righteous uppercase mt-2 bg-brandblue hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded hover:opacity-90`}>{buttonText}</Link>
  </div>
}


function Home() {
  return <div className={`w-full flex flex-col items-center`}>

    <div className={`w-full pt-12 pb-16 flex flex-col items-center`} style={{ background: 'linear-gradient(rgba(22, 28, 46, 0.8), rgba(22, 28, 46, 0.8)), url(/images/jessica-lewis-fJXv46LT7Xk-unsplash.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>

      <div className={`max-w-4xl px-10 flex flex-col items-center text-center gap-4`}>
        <img className={`w-64`} src='/logos/logo-white-no-strapline.svg' alt='ChecQR Logo' />
        <h2 className={`mt-4 font-righteous uppercase text-white text-3xl font-bold`}>Operational record keeping </h2>

        <p className={`text-white`}>ChecQR is a platform for performing operational checks with mobile phones and tablets.
        </p>


        <Link to='/contact' className={`font-righteous uppercase mt-4 bg-white hover:bg-opacity-90 text-brandblue font-bold py-2 px-4 rounded hover:opacity-90`}>Contact us</Link>

      </div>
    </div>

    <div className={`w-full bg-white flex flex-col items-center justify-center text-center`}>

      <div className={`max-w-4xl py-10 px-10 bg-white w-full flex flex-col items-center justify-center`}>

        <h3 className={`mb-4 font-righteous uppercase text-brandblue text-3xl font-bold`}>
          Checqr consists of:</h3>
        <div className={`flex md:flex-row flex-col md:text-center text-left gap-4 text-sm`}>
          <div className={`w-full flex flex-row md:flex-col items-center gap-3`}>
            <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
            A simple, friendly experience for carrying out operational checks on mobile devices which is focused on telling the user what needs to be done and helping them to do it</div>
          <div className={`w-full flex flex-row md:flex-col items-center gap-3`}>
            <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
            A powerful dashboard with an easy to use builder to allow rapid creation of operational checking processes and instant access to results</div>
          <div className={`w-full flex flex-row md:flex-col items-center gap-3`}>
            <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
            A flexible notifications system to make sure the right people find out if expected checks are missed or results require further attention</div>
          <div className={`w-full flex flex-row md:flex-col items-center gap-3`}>
            <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
            An audit portal to give logged, read only access to auditors to comply with contractual, legal or management system audit requirements</div>
          <div className={`w-full flex flex-row md:flex-col items-center gap-3`}>
            <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
            A corrective action process enabling issues to be flagged, addressed and closed and recorded by the appropriate individuals.</div>
        </div>
      </div>
    </div>





    <div className={`w-full max-w-4xl flex flex-col pt-12 pb-6 items-center`}>
      <h3 className={`font-righteous uppercase text-brandblue text-3xl font-bold`}>What can I check with ChecQR?</h3>
      <div className={`mt-12 flex flex-row justify-center gap-4 flex-wrap px-10`}>

        <ProjectThumbnail
        image={'/icons/icon-customchecklists.svg'}
        title="Custom checklists"
        description="Stay on top of what needs to be done in your workplace. Set up simple, customisable checklists and helpful reminders."
        button="Watch the video"
        link=""
        textcolor="text-customloggingorange"
        bgcolor="bg-customloggingorange"
      />
        {/* <ProjectThumbnail
          image={'/icons/icon-incident.svg'}
          title="Incident logging"
          description="Keep a digital record of accidents and near misses that happen in your workplace, including any which are reportable to RIDDOR."
          button="Find out more"
          link="/incident-logging"
          textcolor="text-firstaidgreen"
          bgcolor="bg-firstaidgreen"
        /> */}
        <ProjectThumbnail
          image={'/icons/icon-temps.svg'}
          title="Fridge & freezer temperatures"
          description="Help prevent spoilage by keeping accurate daily digital records of fridge and freezer temperatures in your commercial kitchen."
          button="Find out more"
          link="/temperature-logging"
          textcolor="text-temploggingblue"
          bgcolor="bg-temploggingblue"
        />
        <ProjectThumbnail
          image={'/icons/icon-assets.svg'}
          title="Company assets"
          description="Keep track of your company’s assets, including vehicles, tools and machinery. Maintain a live location map and maintenance records."
          button="Find out more"
          link="/asset-management"
          textcolor="text-assetsblue"
          bgcolor="bg-assetsblue"
        />


      </div>
    </div>



    <div className={`bg-brandblue text-white w-full flex flex-col items-center justify-center`}>
      <div className={`px-10 mt-10 mb-3 w-full max-w-4xl text-center`}>
        Our friendly software makes it easy for operational staff to carry out checks and gather evidence across your operations.  Real time sharing of data and highly configurable alerts give managers and compliance teams a clear vision of current operational reality.
      </div>
      <div className={`w-full max-w-4xl py-10 px-10 flex flex-col gap-6 md:flex-row md:items-start items-center `}>
        <div className={`w-full flex flex-row md:flex-col items-center text-center  gap-3`}>
          <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
          Easy, real-time, operational checking
        </div>
        <div className={`w-full flex flex-row md:flex-col items-center text-center  gap-3`}>
          <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
          Support for all mobile devices
        </div>
        <div className={`w-full flex flex-row md:flex-col items-center text-center  gap-3`}>
          <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
          Simple checklist builder
        </div>
        <div className={`w-full flex flex-row md:flex-col items-center text-center  gap-3`}>
          <img src={`/icons/green-tick-icon.png`} alt={''} className={`w-10`} />
          Advanced notification options
        </div>
      </div>
    </div>

    <div className={`bg-white w-full flex flex-col items-center justify-center`}>
      <div className={`w-full max-w-4xl flex flex-col md:flex-row pt-6 justify-center`}>
        <GeneralContentSection
          imageUrl={`images/kitchen-holding-ipad.jpg`}
          title={`Product update blog`}
          text={`We are continuously improving ChecQR with new features. Take a look at our blog to find out more about our latest product developments.`}
          buttonUrl={`/blog`}
          buttonText={`View blog posts`}
        />

        <GeneralContentSection
          imageUrl={`images/hi-vis-ipad.jpg`}
          title={`Partnerships`}
          text={`Interested in partnering with us?`}
          buttonUrl={`/partnerships`}
          buttonText={`Find out more`}
        />
      </div>
    </div>
  </div >
}

export default Home