function Footer() {
  return <div className={`w-full py-6 flex flex-col items-center bg-brandblue`}>
    <div className={`max-w-4xl px-10 text-center`}>

      <div className={'flex flex-row gap-5 items-center justify-between pb-5 mb-5 border-b border-gray-700'}>
        <a href='/'><img className={`w-36`} src='/logos/logo-white-no-strapline.svg' alt='ChecQR Logo' /></a>

        <div className={'flex flex-row gap-5 items-center'}>
          <a href='https://www.linkedin.com/company/93777705/admin/feed/posts/'><img className={`h-7`} src='/icons/social-linkedin.svg' alt='LinkedIn' /></a>
          {/* <a href=''><img className={`h-7`} src='/social-twitter.svg' alt='Twitter' /></a>
        <a href=''><img className={`h-7`} src='/social-instagram.svg' alt='Instagram' /></a> */}
          <a href='https://www.youtube.com/@ChecQR'><img className={`h-7`} src='/icons/social-youtube.svg' alt='YouTube' /></a>
        </div>

      </div>

      <p className={`text-white text-xs`}>
        Icon images from <a href='https://thenounproject.com/' className={`underline`}>Noun Project</a> (CC BY 3.0). Checklist by Mada Creative, Health by Adrien Coquet, Vehicle by Royyan Wijaya, Temperature by Katarina Kärnekull.

        Header photo by <a href="https://unsplash.com/@jessicalewiscreative?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className={`underline`}>Jessica Lewis</a> on <a href="https://unsplash.com/photos/fJXv46LT7Xk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className={`underline`}>Unsplash</a>

  </p>
    </div>
  </div>
}

export default Footer