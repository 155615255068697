export const getDate = (timestamp, format = 'shortDateAndTime') => {
  const date = new Date(parseInt(timestamp))
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]
  const shortMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const shortMonth = shortMonths[date.getMonth()]
  const day = date.getDate()
  const dayName = days[date.getDay()]
  const shortDayName = shortDays[date.getDay()]
  const hours = `${date.getHours()}`.padStart(2, '0')
  const minutes = `${date.getMinutes()}`.padStart(2, '0')
  const seconds = `${date.getSeconds()}`.padStart(2, '0')
  const dayAndDate = `${shortDayName} ${day}`
  const dayDateMonth = `${shortDayName} ${day} ${shortMonth}`
  const formattedDate = `${shortDayName} ${day}, ${month}, ${year}`
  const formattedDateAndTime = `${shortDayName} ${day} ${month} ${year}, ${hours}:${minutes}`
  const shortDateAndTime = `${shortDayName} ${day}, ${hours}:${minutes}`
  const justHoursAndMinutes = `${hours}:${minutes}`



  if (format === 'day') {
    return day
  } else if (format === 'month') {
    return month
  } else if (format === 'year') {
    return year
  } else if (format === 'dayDateMonth') {
    return dayDateMonth
  } else if (format === 'dayAndDate') {
    return dayAndDate
  } else if (format === 'shortDateAndTime') {
    return shortDateAndTime
  } else if (format === 'formattedDateAndTime') {
    return formattedDateAndTime
  } else if (format === 'formattedDate') {
    return formattedDate
  } else if (format === 'justHoursAndMinutes') {
    return justHoursAndMinutes
  } else {
    console.log('🚫 Please specify a format')
    return null
  }
}