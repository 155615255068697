import Children, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import ReactMarkdown from 'react-markdown'
import React from 'react'
import Spinner from './Spinner'
import BlogArticles from '../combined-blogs.json'
import { convertDateStringToTimestamp } from '../utils/convertDateStringToTimestamp'
import { getDate } from '../utils/getDate'

type ObjectAny = {
  [key: string]: any
}

type Props = {
}



function BlogArticle({ }: Props) {

  const { blogtitle } = useParams()
  const combinedArticles: ObjectAny = { ...BlogArticles }




  return <div className={`bg-gray-100 w-full flex flex-col items-center`}>
    <div className={`w-full max-w-4xl py-10 px-10 flex flex-col items-center`}>



      {combinedArticles && blogtitle && !combinedArticles[blogtitle] && <div className={` flex flex-col gap-2 justify-center items-center`}>
        <p>Sorry - couldn't find this blog post</p>
        <Link to='/blog' className={`font-righteous uppercase mt-4 text-white hover:bg-opacity-90 bg-brandblue font-bold py-2 px-4 rounded hover:opacity-90`}>Back to blog posts</Link>
      </div>}

      {combinedArticles && blogtitle && combinedArticles[blogtitle] && <div
        className={` flex flex-col gap-4 justify-start items-start`}>
        {combinedArticles[blogtitle].image && <img
          className={`w-full rounded mb-4`}
          src={combinedArticles[blogtitle].image}
          alt=''
        />}
        <h3
          className={`font-righteous uppercase text-4xl text-brandblue`}>{combinedArticles[blogtitle].title}</h3>
        {combinedArticles[blogtitle].date && <p className={`text-sm text-gray-600 italic`}>
        {getDate(convertDateStringToTimestamp(combinedArticles[blogtitle].date), 'formattedDate')}
        </p>}

        {combinedArticles[blogtitle].content && combinedArticles[blogtitle].content.map((paragraph: string, index: number) => {
          return <p key={index}>{paragraph}</p>
        })}



        <Link to='/blog' className={`font-righteous uppercase mt-6 text-white hover:bg-opacity-90 bg-brandblue font-bold py-2 px-4 rounded hover:opacity-90 text-center`}>Back to blog posts</Link>
      </div>}




    </div>
  </div>
}

export default BlogArticle