import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import Header from './components/Header'
import Home from './components/Home'
import Project from './components/Project'
import Footer from './components/Footer'
import TagManager from 'react-gtm-module'
import Contact from './components/Contact'
import BlogArticle from './components/BlogArticle'
import BlogList from './components/BlogList'
import Partnerships from './components/Partnerships'
import Retail from './components/Retail'

function App() {

  TagManager.initialize({
    gtmId: 'GTM-WPDRVXZQ'
  })

  return <div className={`w-full flex flex-col min-h-screen justify-between bg-gray-200`}>
    <Router>
      <div>
        <Routes>
          <Route
            path='/legal'
            element={<><Header />
              *Legal*</>} />
          <Route
            path='/*'
            element={<Home />} />

          <Route
            path='/contact'
            element={<><Header />
              <Contact /></>} />

          <Route
            path='/partnerships'
            element={<><Header />
              <Partnerships /></>} />

          <Route
            path='/blog'
            element={<><Header />
              <BlogList /></>} />

          <Route
            path='/blog/:blogtitle'
            element={<><Header />
              <BlogArticle /></>} />

          <Route
            path='/retail'
            element={<><Header />
              <Retail /></>} />

          <Route
            path='/asset-management'
            element={<><Header />
              <Project
                image={'/icons/icon-assets.svg'}
                title="Asset management"
                button="Watch the video"
                videolink="https://www.youtube.com/embed/agSRqJpvRQ0"
                textcolor="text-assetsblue"
                bgcolor="bg-assetsblue"
              >
                <p className={`mb-4`}>Whether in a humble workshop, a large industrial complex or even for those with assets in the public realm, keeping track of assets such as: machinery, vehicles, hazardous chemicals or service points is vital to keeping on top of risk.</p>
                <p className={`mb-4`}>ChecQR provides a digital log which allows you to add assets in real time, with photos and notes to aid identification and asset location available for large outdoor sites. Asset stickers can be added so that services histories can easily be accessed and updated when needed.</p>
                <p className={`mb-4`}>In automatically generating a full audit trail, ChecQR removes the need for tedious manual systems and instead corroborates data centrally in an easy to understand way. Our asset tracking systems are fully customisable to your business needs, making us able to adjust functionality to your specific requirements where necessary.</p>
                <p className={`mb-4`}>ChecQR replaces disparate spreadsheets, clipboards and email threads. Because one clear record is shared by the whole team in real-time, this helps avoid misunderstandings, misplaced documents and confusion.</p>
                <p className={`mb-4`}>ChecQR is a web-based platform meaning it requires no app and will work with Android and iPhone devices.</p>
              </Project></>} />

          <Route
            path='/incident-logging'
            element={<><Header />
              <Project
                image={'/icons/icon-incident.svg'}
                title="Incident logging"
                button="Watch the video"
                videolink="https://www.youtube.com/embed/rVvvZ4wSD7s"
                textcolor="text-firstaidgreen"
                bgcolor="bg-firstaidgreen"
              >
                <p className={`mb-4`}>Whilst in an ideal world, accidents could be completely mitigated against, unfortunately accidents do happen. Employers have a legal duty to record accidents and near misses. Failure to comply with this duty leaves you open to legal action and even criminal prosecution by the HSE, with several notable cases <a href='https://brodies.com/insights/health-and-safety/failure-to-report-a-health-and-safety-incident-under-riddor-results-in-jail-term/#:~:text=In%20what%20is%20believed%20to,building%20site%20under%20his%20control.' className={`underline`} target="_blank">in the last few years</a>.</p>
                <p className={`mb-4`}>With traditional paper based accident books, pages can easily be lost or damaged and data is impossible to search or categorise, making it difficult to use comparative data to improve health and safety performance.</p>
                <p className={`mb-4`}>ChecQR replaces a paper accident book with a digital one. QR code stickers can be placed in first aid kits, on notice books, or in fact wherever makes sense for you and scanned when a new entry needs to be made. These records appear in real-time and notify the relevant manager when an incident is reported. Details of incidents are only made available to those who need them - usually the person(s) involved and the health and safety manager.</p>
                <p className={`mb-4`}>ChecQR is a web-based platform meaning it requires no app and will work with Android and iPhone devices.</p>


              </Project></>} />

          <Route
            path='/temperature-logging'
            element={<><Header />
              <Project
                image={'/icons/icon-temps.svg'}
                title="Temperature logging"
                button="Watch the video"
                videolink="https://www.youtube.com/embed/tjgTmQrn7i8"
                textcolor="text-temploggingblue"
                bgcolor="bg-temploggingblue"
              >
                <p className={`mb-4`}>Keeping good fridge temperature records is important. From swiftly identifying malfunctioning equipment and keeping your customers safe, to reducing energy usage; accurate and reliable readings are crucial.</p>
                <p className={`mb-4`}>Manual systems for recording fridge and freezer temperatures are comparatively cumbersome. They require logging in a book, they aren’t digitised, can be hard to check back on retroactively and can’t easily be sent to others where needed. Similarly these log books can be damaged or misplaced and it is very easy to forget to record these vital readings on busy days.</p>
                <p className={`mb-4`}>ChecQR replaces this physical log book with a digital record. A unique QR code sticker is attached to each fridge, which when scanned, automatically adds a time stamp and photo of the temperature gauge, along with a reading. For those without a temperature readout, our QR codes can be attached to a digital thermometer placed inside.</p>
                <p className={`mb-4`}>This avoids mix ups between fridges and each new entry appears on the dashboard in real time. Notifications can be delivered when readings are overdue. Extra confirmation is given when all fridges are checked, making it easy to ensure your records are complete and kept to a high standard. This is useful when trying to demonstrate to inspectors the quality of your food hygiene and safety procedures.</p>
                <p className={`mb-4`}>ChecQR is a web-based platform meaning it requires no app and will work with Android and iPhone devices.</p>
              </Project></>} />

        </Routes>
      </div>
      <Footer />
    </Router>





  </div>
}

export default App
