import Children from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"


type ProjectProps = {
  title: string
  button: string
  bgcolor: string
  textcolor: string
  image: string
  videolink: string
  children: any
}


function Project({ title, button, bgcolor, textcolor, image, videolink, children }: ProjectProps) {

  return <div className={`bg-gray-100 w-full flex flex-col items-center`}>
    <div className={`w-full max-w-4xl py-10 px-10 flex flex-col items-center`}>
      <img className={`w-28`} src={image} alt='Icon' />
      <h3 className={`mt-4 mb-6 font-righteous uppercase text-4xl ${textcolor}`}>{title}</h3>
      <div className={`flex md:flex-row flex-col md:items-start items-center justify-center gap-7 pt-6`}>

        <div className={`md:text-left text-center`}>
          {children}
        </div>

        {videolink && <div className={`border border-gray-300 rounded hover:opacity-90 text-center bg-white drop-shadow-xl p-4`}>


      <h3 className={`mb-3 font-righteous uppercase text-xl ${textcolor}`}>Watch the video</h3>
          <iframe width="320" height="175"
          src={videolink}>
        </iframe></div>}

      </div>



    </div>
  </div>
}

export default Project